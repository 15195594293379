import React, { useCallback } from 'react';
import styles from './header.module.scss';
import { logo } from '../../utils/general-code-import/images-import';
import { phoneSD } from '../../utils/mock';
import { useCustomNavigate } from '../../utils/general-code-import/utils-import';
import { BASE_PATH } from '../../routes';
export const Header = () => {
    const navigate = useCustomNavigate();
    const onGoMain = useCallback(() => navigate(BASE_PATH), [navigate]);
    return (React.createElement("header", { className: styles.header },
        React.createElement("img", { onClick: onGoMain, className: styles.logo, src: logo, alt: '\u041B\u043E\u0433\u043E\u0442\u0438\u043F' }),
        React.createElement("div", null, phoneSD.map(item => (React.createElement("p", { key: item.id, className: styles.infoPhoneSd }, item.phone))))));
};
