export const certificateInfo = [
    {
        id: 1,
        fio: 'Элиановский Денис Олегович',
        expirationDate: '2023-04-17T08:26:04.099Z',
        isActive: true,
    },
    {
        id: 2,
        fio: 'Жуков Серей Иванович',
        expirationDate: '2023-04-17T08:26:04.099Z',
        isActive: true,
    },
    {
        id: 3,
        fio: 'Элиановский Денис Олегович',
        expirationDate: '2021-04-17T08:26:04.099Z',
        isActive: false,
    },
];
export const phoneSD = [
    {
        id: 1,
        phone: '8 (495) 782-46-52 (Москва и МО)',
    },
    {
        id: 2,
        phone: '8 (800) 600-46-52 (Все регионы)',
    },
];
