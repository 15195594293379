import React, { useCallback } from 'react';
import { Popup, RectButtonUI } from '../../utils/general-code-import/components-import';
import styles from './login.module.scss';
/** Диалог подтверждения информации об ошибки */
export const ConfirmErrorInfoPopup = ({ onClose, onSubmit, errorText, ...rest }) => {
    const handleSubmit = useCallback(() => {
        onSubmit();
        if (onClose)
            onClose();
    }, [onClose, onSubmit]);
    return (React.createElement(Popup, { onClose: onClose, header: '\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435', footer: React.createElement(RectButtonUI, { "data-testid": 'confirm', styleType: 'green', onClick: handleSubmit }, "\u041E\u043A"), ...rest },
        React.createElement("div", { className: styles.childContainer }, errorText)));
};
