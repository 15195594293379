import React from 'react';
import cn from 'classnames';
import styles from './navigation.module.scss';
import { sectionsInfo } from '../../utils/navigationOptions';
const MenuItem = ({ items, href, label }) => (React.createElement("div", { className: styles.itemContainer },
    React.createElement("span", { className: cn(styles.link, {
            [styles.linkExist]: href,
        }) }, href ? (React.createElement("a", { className: styles.link, target: '_blank', href: href, rel: 'noreferrer' }, label)) : (label)),
    items && (React.createElement("ul", null, items.map(({ label, href }) => (React.createElement("li", { key: label },
        React.createElement("a", { key: href, target: '_blank', href: href, rel: 'noreferrer' }, label))))))));
export const Navigation = () => (React.createElement("div", { className: styles.navigation },
    React.createElement("nav", null,
        React.createElement("div", { className: styles.sections }, sectionsInfo.map(({ label, nested, href }) => (React.createElement(MenuItem, { href: href, key: label, label: label, items: nested })))))));
