import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { accountManagementClient } from '../../utils/api';
import styles from '../password-change/password-change.module.scss';
import { InputUI, RectButtonUI } from '../../utils/general-code-import/components-import';
import { useAlert, useCustomNavigate, validatePassword, } from '../../utils/general-code-import/utils-import';
import { BASE_PATH } from '../../routes';
import { validateRepeatNewPassword } from '../../utils/smallFunctions';
import { ApiException } from '../../../submodules/inkh-keycloak/src/Inkh.Keycloak.Api.Client.TypeScript/Clients/AccountManagementClient.Generated';
/**
 * Компонент для смены или восстановления пароля
 * */
export const ForgotPassword = () => {
    const { getValues, register, handleSubmit, formState: { errors, isValid }, } = useForm({ mode: 'onChange' });
    /** Получение id из url */
    const { id } = useParams();
    /** Индикатор загрузки */
    const [loading, setLoading] = useState(false);
    /** Параметры валидации пароля */
    const [passwordParamsValidate, setPasswordParamsValidate] = useState(null);
    /** Оповещения */
    const { errorAlert, successAlert } = useAlert();
    const navigate = useCustomNavigate();
    /** Обработчик получения требований к паролю */
    useEffect(() => {
        setLoading(true);
        accountManagementClient
            .getPasswordPolicy()
            .then(res => setPasswordParamsValidate(res))
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => {
            setLoading(false);
        });
    }, [errorAlert]);
    /** Обработчик события по смене или восстановлению пароля */
    const onSubmit = useCallback(({ login, newPassword }) => {
        if (!id) {
            /** Восстановление пароля */
            setLoading(true);
            accountManagementClient
                .forgotPassword({ login })
                .then(() => successAlert('На почту отправлено письмо'))
                .catch(() => errorAlert('Произошла ошибка'))
                .finally(() => {
                setLoading(false);
                navigate(BASE_PATH);
            });
        }
        else {
            /** Смена пароля по ссылке */
            setLoading(true);
            accountManagementClient
                .changeForgotPassword({
                linkId: id,
                newPassword,
                userLogin: login,
            })
                .then(() => successAlert('Пароль успешно изменен'))
                .catch((err) => {
                if (err instanceof ApiException) {
                    err.response.forEach(({ errors }) => errors.forEach(({ errorMessage }) => errorAlert(errorMessage)));
                }
            })
                .finally(() => {
                setLoading(false);
                navigate(BASE_PATH);
            });
        }
    }, [errorAlert, id, navigate, successAlert]);
    return (
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
    React.createElement("form", { onSubmit: handleSubmit(onSubmit), className: styles.passwordChange },
        React.createElement("h1", { className: styles.title }, "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u0430\u0440\u043E\u043B\u044F"),
        React.createElement("div", { className: styles.section },
            React.createElement("div", { className: styles.sectionInputs },
                React.createElement(InputUI, { ...register('login', {
                        required: 'Введите логин',
                        disabled: loading,
                    }), hasError: !!errors.login, errorText: errors.login?.message, title: '\u041B\u043E\u0433\u0438\u043D' }),
                id && (React.createElement(React.Fragment, null,
                    React.createElement(InputUI, { ...register('newPassword', {
                            required: 'Введите новый пароль',
                            disabled: loading,
                            validate: value => validatePassword(value, passwordParamsValidate),
                        }), isPasswordInput: true, hasError: !!errors.newPassword, errorText: errors.newPassword?.message, title: '\u041D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C', type: 'password' }),
                    React.createElement(InputUI, { ...register('repeatNewPassword', {
                            required: 'Повторите новый пароль',
                            disabled: loading,
                            validate: value => validateRepeatNewPassword(value, getValues().newPassword),
                        }), isPasswordInput: true, hasError: !!errors.repeatNewPassword, errorText: errors.repeatNewPassword?.message, title: '\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C', type: 'password' }))))),
        React.createElement(RectButtonUI, { disabled: !isValid || loading, type: 'submit' }, id ? 'Сменить' : 'Восстановить')));
};
