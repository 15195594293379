import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './password-change.module.scss';
import { InputUI, RectButtonUI } from '../../utils/general-code-import/components-import';
import { tokenClient } from '../../utils/api';
import { useAlert, useCustomNavigate } from '../../utils/general-code-import/utils-import';
import { BASE_PATH } from '../../routes';
import { useAppLinkCookie, useAppLinkWarning } from '../../utils/hooks';
import { validateRepeatNewPassword } from '../../utils/smallFunctions';
export const PasswordChange = ({ destroyApp }) => {
    const { register, getValues, handleSubmit, formState: { errors, isValid }, } = useForm({ mode: 'onChange' });
    const { successAlert, errorAlert } = useAlert();
    /** Ссылка на приложение, на которое нужен редирект */
    const [appLink, , deleteAppLinkFromCookie] = useAppLinkCookie();
    /** Обработка наличия appLink */
    const [render, runWithAppLink] = useAppLinkWarning(appLink);
    const navigate = useCustomNavigate();
    const [loading, setLoading] = useState(false);
    /** Отмена */
    const onCancel = useCallback(() => {
        runWithAppLink(appLink => {
            destroyApp();
            deleteAppLinkFromCookie();
            window.location.assign(appLink);
        });
    }, [deleteAppLinkFromCookie, destroyApp, runWithAppLink]);
    /** Изменение пароля */
    const onSubmit = useCallback(({ currentPassword, newPassword }) => {
        setLoading(true);
        tokenClient
            .changePassword({
            newPassword,
            currentPassword,
        })
            .then(() => successAlert('Пароль успешно изменен'))
            .catch(() => errorAlert('Произошла ошибка'))
            .finally(() => navigate(BASE_PATH));
    }, [errorAlert, navigate, successAlert]);
    return render ? (
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
    React.createElement("form", { onSubmit: handleSubmit(onSubmit), className: styles.passwordChange },
        React.createElement("h1", { className: styles.title }, "\u0421\u043C\u0435\u043D\u0430 \u043F\u0430\u0440\u043E\u043B\u044F"),
        React.createElement("div", { className: styles.section },
            React.createElement("p", { className: styles.sectionText }, "\u0421\u0442\u0430\u0440\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C"),
            React.createElement("div", { className: styles.sectionInputs },
                React.createElement(InputUI, { isPasswordInput: true, ...register('currentPassword', {
                        required: 'Введите старый пароль',
                        disabled: loading,
                    }), hasError: !!errors.currentPassword, errorText: errors.currentPassword?.message, title: '\u0421\u0442\u0430\u0440\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C', type: 'password' }))),
        React.createElement("div", { className: styles.section },
            React.createElement("p", { className: styles.sectionText }, "\u041D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C"),
            React.createElement("div", { className: styles.sectionInputs },
                React.createElement(InputUI, { isPasswordInput: true, ...register('newPassword', {
                        required: 'Введите новый пароль',
                        disabled: loading,
                    }), hasError: !!errors.newPassword, errorText: errors.newPassword?.message, title: '\u041D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C', type: 'password' }))),
        React.createElement("div", { className: styles.section },
            React.createElement("p", { className: styles.sectionText }, "\u041F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C"),
            React.createElement("div", { className: styles.sectionInputs },
                React.createElement(InputUI, { isPasswordInput: true, ...register('newPasswordDuplicate', {
                        required: 'Введите новый пароль',
                        disabled: loading,
                        validate: value => validateRepeatNewPassword(value, getValues().newPassword),
                    }), hasError: !!errors.newPasswordDuplicate, errorText: errors.newPasswordDuplicate?.message, title: '\u041D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C', type: 'password' }))),
        React.createElement("div", { className: styles.submit },
            React.createElement(RectButtonUI, { disabled: !isValid || loading, type: 'submit' }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C"),
            React.createElement(RectButtonUI, { styleType: 'line', onClick: onCancel, disabled: loading }, "\u041E\u0442\u043C\u0435\u043D\u0430")))) : null;
};
