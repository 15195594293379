import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { App } from './components/app';
import { store } from './store/store';
import '../submodules/inkh-core-frontend/src/utils/styles/reset.scss';
import 'reactjs-popup/dist/index.css';
import { AlertContext } from './utils/general-code-import/components-import';
import { iniAPM } from './utils/general-code-import/utils-import';
/** Инициализация elastic APM */
iniAPM('dev_local_Inkh_Legal_KeycloakUI');
/** Контейнер для рендера приложения */
const container = document.querySelector('#root');
if (container) {
    const root = createRoot(container);
    /** Разрушить приложение */
    const destroyApp = () => root.unmount();
    root.render(React.createElement(CookiesProvider, null,
        React.createElement(BrowserRouter, { basename: '/' },
            React.createElement(AlertContext, null,
                React.createElement(Provider, { store: store },
                    React.createElement(App, { destroyApp: destroyApp }))))));
}
