import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { stylesMain } from '../../utils/general-code-import/styles-import';
import styles from './certificates.module.scss';
import { extractErrorMessage, formatDate, useAlert, } from '../../utils/general-code-import/utils-import';
import { createSignature, encodeBase64, getValidCertificates, Preloader, } from '../../../submodules/inkh-core-frontend/src';
import { tokenClient } from '../../utils/api';
import { useAppLinkCookie, useAppLinkWarning, useConfirmErrorInfoPopup, useSessionKey, } from '../../utils/hooks';
import { getUserCertificates } from '../../store/slice/loginSlice';
import { setTimeCookie } from '../../../submodules/inkh-core-frontend/src/utils/auth';
import { ConfirmErrorInfoPopup } from '../login/confirm-error-info-popup';
import { DEFAULT_ERROR_TEXT } from '../../utils/variables';
/**
 * Компонент выбора сертификата
 * */
export const Certificates = ({ destroyApp }) => {
    const { errorAlert } = useAlert();
    /** Получение сессионного ключа */
    const sessionKey = useSessionKey();
    /** получение зарегестрированных сертификатов пользователя */
    const userCertificates = useSelector(getUserCertificates);
    /** Ссылка на приложение, на которое нужен редирект */
    const [appLink, , deleteAppLinkFromCookie] = useAppLinkCookie();
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    /** Валидные сертификаты */
    const [certificate, setCertificate] = useState(null);
    /** Ошибка плагина  */
    const [cadesError, setCadesError] = useState(false);
    /** Обработка наличия appLink */
    const [render, runWithAppLink] = useAppLinkWarning(appLink);
    /** состояние открытия попапа, текста ошибки и обработчики установки этих значений */
    const [openPopup, errorText, handleSetOpenPopup, handleSetErrorText, onClosePopup, onConfirm] = useConfirmErrorInfoPopup();
    /** Перехват ошибки, если пользователь нажал "нет" в окне cades plugin */
    useEffect(() => {
        const listener = () => {
            setCadesError(true);
            setLoading(false);
        };
        window.addEventListener('unhandledrejection', listener);
        return () => window.removeEventListener('unhandledrejection', listener);
    }, []);
    /** Получение валидных сертификатов */
    useEffect(() => {
        setLoading(true);
        getValidCertificates()
            .then(certs => setCertificate(certs.filter(cert => userCertificates.includes(cert.thumbprint))))
            .catch(() => {
            setCadesError(true);
            errorAlert('Произошла ошибка');
        })
            .finally(() => setLoading(false));
    }, [errorAlert, userCertificates]);
    /** Выбор сертификата */
    const onLoginByCertificate = (cert) => () => {
        setLoading(true);
        const base64 = encodeBase64(sessionKey);
        const promise = createSignature(cert, base64);
        promise
            .then(signature => {
            tokenClient
                .getTokenBy2FaCert({ signature, body: base64 })
                .then(tokenLifeTime => {
                setTimeCookie(tokenLifeTime);
                runWithAppLink(appLink => {
                    /** Очистка приложения */
                    destroyApp();
                    /** Удаление ссылки на приложение из cookie */
                    deleteAppLinkFromCookie();
                    /** Переход на само приложение */
                    window.location.assign(appLink);
                });
            })
                .catch((error) => {
                handleSetOpenPopup(true);
                handleSetErrorText(extractErrorMessage(error, DEFAULT_ERROR_TEXT));
                errorAlert(extractErrorMessage(error, DEFAULT_ERROR_TEXT));
            })
                .finally(() => {
                setLoading(false);
            });
        })
            .catch((error) => {
            errorAlert(extractErrorMessage(error));
            setLoading(false);
        });
    };
    return render ? (React.createElement(React.Fragment, null,
        React.createElement("div", null, loading ? (React.createElement(Preloader, null)) : (React.createElement(React.Fragment, null,
            cadesError && (React.createElement("div", { className: styles.cadesError },
                "\u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435! \u0422\u0440\u0435\u0431\u0443\u0435\u043C\u044B\u0439 \u0434\u043B\u044F \u0440\u0430\u0431\u043E\u0442\u044B \u041A\u0440\u0438\u043F\u0442\u043E\u041F\u0440\u043E \u042D\u0426\u041F Browser plug-in \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u0435\u043D. \u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u0435\u0433\u043E \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 \u0438\u043B\u0438 \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043A\u0430\u0447\u0430\u0442\u044C \u0438 \u0443\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u0435\u0433\u043E \u0431\u043E\u043B\u0435\u0435 \u043D\u043E\u0432\u0443\u044E \u0432\u0435\u0440\u0441\u0438\u044E \u0441 \u0441\u0430\u0439\u0442\u0430 \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044F:",
                ' ',
                React.createElement("a", { className: styles.downloadPlugin, href: 'https://www.cryptopro.ru/products/cades/plugin/get_2_0' }, "\u0441\u043A\u0430\u0447\u0430\u0442\u044C \u043F\u043B\u0430\u0433\u0438\u043D"))),
            !cadesError && certificate?.length ? (certificate.map(cert => (React.createElement("div", { onClick: onLoginByCertificate(cert), key: cert.publicKey, className: styles.certificate },
                React.createElement("div", null,
                    React.createElement("p", { className: stylesMain.tableTextHead }, cert.name),
                    React.createElement("p", { className: stylesMain.tableTextBody },
                        "\u0414\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u0434\u043E ",
                        formatDate(cert.validTo))),
                React.createElement("div", { className: stylesMain.flexContainer },
                    !(new Date() <= cert.validTo && new Date() >= cert.validFrom) && (React.createElement("span", { className: cn(stylesMain.indicator, stylesMain.indicatorClose) })),
                    React.createElement("p", { className: stylesMain.tableTextBody }, new Date() <= cert.validTo && new Date() >= cert.validFrom ? '' : 'Истек')))))) : (React.createElement("span", { className: styles.nonCertificate }, "\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u044B \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B"))))),
        React.createElement(ConfirmErrorInfoPopup, { open: openPopup, onClose: onClosePopup, onSubmit: onConfirm, errorText: errorText }))) : null;
};
