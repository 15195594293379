import { createSlice } from '@reduxjs/toolkit';
import { parametersTabs } from '../../utils/tabOptions';
const initialState = {
    activeTab: parametersTabs[0],
};
const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveTab(state, action) {
            state.activeTab = action.payload;
        },
    },
});
export const getActiveTab = (state) => state.tabs.activeTab;
export const { setActiveTab } = tabSlice.actions;
export const tabReducer = tabSlice.reducer;
