import React from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { Login } from '../login';
import { PasswordChange } from '../password-change';
import { Layout } from '../layout';
import { AUTH_TOTP_AND_CERT, BASE_PATH, CHANGE_PASSWORD, FORGOT_PASSWORD, ID_TEMPLATE_PATH, AUTH_TOTP, TWO_FACTOR_AUTH, AUTH_CERT, } from '../../routes';
import { ForgotPassword } from '../forgot-password';
import { AuthLayout } from '../auth-layout';
import { TwoFactorAuth } from '../two-factor-auth';
export const App = ({ destroyApp }) => (React.createElement(Routes, null,
    React.createElement(Route, { path: BASE_PATH, element: React.createElement(Layout, null) },
        React.createElement(Route, { index: true, element: React.createElement(Login, null) }),
        React.createElement(Route, { path: CHANGE_PASSWORD, element: React.createElement(PasswordChange, { destroyApp: destroyApp }) }),
        React.createElement(Route, { path: FORGOT_PASSWORD, element: React.createElement(Outlet, null) },
            React.createElement(Route, { index: true, element: React.createElement(ForgotPassword, null) }),
            React.createElement(Route, { path: ID_TEMPLATE_PATH, element: React.createElement(ForgotPassword, null) })),
        React.createElement(Route, { path: TWO_FACTOR_AUTH, element: React.createElement(TwoFactorAuth, null) }),
        React.createElement(Route, { path: AUTH_TOTP_AND_CERT, element: React.createElement(AuthLayout, { destroyApp: destroyApp }) }),
        React.createElement(Route, { path: AUTH_TOTP, element: React.createElement(AuthLayout, { destroyApp: destroyApp, isTotp: true }) }),
        React.createElement(Route, { path: AUTH_CERT, element: React.createElement(AuthLayout, { destroyApp: destroyApp, isCert: true }) }),
        React.createElement(Route, { path: '*', element: React.createElement(Navigate, { to: BASE_PATH, replace: true }) }))));
