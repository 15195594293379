import React, { useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import cn from 'classnames';
import styles from './pin-code-input.module.scss';
import { digitsRegexp } from '../../utils/variables';
/**
 * Компонент для ввода пин кода
 * */
export const PinCodeInput = forwardRef(({ digits, changeHandler, handleConfirm, isNonEmptyStrings }, ref) => {
    /** здесь будут ссылки на input-элементы */
    const inputRefs = useRef([]);
    useImperativeHandle(ref, () => ({
        current: {
            focus: () => inputRefs.current[0].focus(),
        },
    }));
    /** при монтировании компонента фокус на первое поле */
    useEffect(() => {
        if (!isNonEmptyStrings) {
            inputRefs.current[0].focus();
        }
    }, [isNonEmptyStrings]);
    /** Обработчик при нажатии клавиши */
    const handleKeyDown = useCallback((index, event) => {
        if (event.key === 'Backspace') {
            event.preventDefault();
            if (digits[index].match(digitsRegexp)) {
                /** если элемент массива digits содержит цифру, то при нажатии клавиши */
                /** вызываем callback-функцию родителя, чтобы записать пустую строку */
                /** копия digits */
                const newDigits = [...digits];
                newDigits[index] = '';
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
                changeHandler(newDigits);
            }
            else if (index > 0) {
                /** элемент массива digits пустой, удалять нечего — так что надо сместить */
                /** фокус на предыдущее поле input — при условии, что это не первое поле */
                inputRefs.current[index - 1].focus();
            }
        }
        else if (event.key === 'Enter' && isNonEmptyStrings) {
            event.preventDefault();
            handleConfirm();
        }
    }, [changeHandler, digits, handleConfirm, isNonEmptyStrings]);
    const handleChange = useCallback((index, newValue) => {
        const oldDigit = digits[index];
        /** старую цифру в поле ввода убираем, оставляя только новую */
        const newDigit = newValue.trim().replace(oldDigit, '');
        /** если это не цифра, ничего не делаем, пока не будет цифры */
        if (newDigit < '0' || newDigit > '9')
            return;
        /** теперь вызываем callback родителя, чтобы обовить digits */
        const newDigits = [...digits];
        /** копия digits */
        newDigits[index] = newDigit;
        changeHandler(newDigits);
        /** смещаем фокус на следующее поле для ввода следующей цифры */
        if (index < digits.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    }, [changeHandler, digits]);
    return (React.createElement("div", null, digits.map((digit, index) => (React.createElement("input", { key: index, className: cn(styles.input, {
            [styles.active]: digit.match(digitsRegexp),
        }), value: digit, onChange: event => handleChange(index, event.target.value), onKeyDown: event => handleKeyDown(index, event), ref: element => {
            if (element) {
                inputRefs.current[index] = element;
            }
        } })))));
});
PinCodeInput.displayName = 'PinCodeInput';
