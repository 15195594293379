import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    loginErrorText: '',
    loginError: false,
    sessionKey: '',
    userCertificates: [],
};
export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setSessionKey: (state, action) => {
            state.sessionKey = action.payload;
        },
        setUserCertificates: (state, action) => {
            state.userCertificates = action.payload;
        },
    },
});
export const { setSessionKey, setUserCertificates } = loginSlice.actions;
export const getLogin = (state) => state.login;
export const getSessionKey = (state) => state.login.sessionKey;
export const getUserCertificates = (state) => state.login.userCertificates;
export const loginReducer = loginSlice.reducer;
