import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { setSessionKey, setUserCertificates } from '../../store/slice/loginSlice';
import styles from './login.module.scss';
import { extractErrorMessage, useAlert, useCustomNavigate, } from '../../utils/general-code-import/utils-import';
import { CustomLink, InputUI, Preloader, RectButtonUI, } from '../../utils/general-code-import/components-import';
import { tokenClient } from '../../utils/api';
import { AUTH_CERT, AUTH_TOTP, AUTH_TOTP_AND_CERT, FORGOT_PASSWORD, TWO_FACTOR_AUTH, } from '../../routes';
import { useAppDispatch } from '../../store/store';
import { TwoFaOptions } from '../../../submodules/inkh-keycloak/src/Inkh.Keycloak.Api.Client.TypeScript/Models/TwoFaOptions.Generated';
import { ConfirmErrorInfoPopup } from './confirm-error-info-popup';
import { useConfirmErrorInfoPopup } from '../../utils/hooks';
import { DEFAULT_ERROR_TEXT } from '../../utils/variables';
export const Login = () => {
    const dispatch = useAppDispatch();
    const { errorAlert, warningAlert } = useAlert();
    const navigate = useCustomNavigate();
    /** Опции для работы с react-hook-form */
    const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm({
        mode: 'onChange',
    });
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    /** состояние открытия попапа, текста ошибки и обработчики установки этих значений */
    const [openPopup, errorText, handleSetOpenPopup, handleSetErrorText, onClosePopup, onConfirm] = useConfirmErrorInfoPopup();
    /** Проверка на настройки авторизации */
    const isOptionsSettings = (twoFAOptions, twoFAOSettings) => twoFAOptions.every(item => twoFAOSettings.includes(item));
    /** Обработчик события формы логина */
    const onLoginSubmit = useCallback((formLogin) => {
        /** Установка состояние загрузки */
        setLoading(true);
        tokenClient
            .login2FA(formLogin)
            .then(({ sessionKey, auth2FASettings, secondsLeft }) => {
            if (sessionKey) {
                /** Сгорание сессионного ключа */
                setTimeout(() => {
                    dispatch(setSessionKey(''));
                    warningAlert('Ваша сессия истекла, необходима авторизация', false);
                }, secondsLeft * 1000);
                if (auth2FASettings && auth2FASettings.options) {
                    /** Установка сессионного ключа в редакс */
                    dispatch(setSessionKey(sessionKey));
                    /** Навигация в зависимости от полученных настроек */
                    if (isOptionsSettings([TwoFaOptions.TotpSetup, TwoFaOptions.TotpEnabled], auth2FASettings.options)) {
                        navigate(TWO_FACTOR_AUTH);
                    }
                    else if (isOptionsSettings([TwoFaOptions.TotpEnabled, TwoFaOptions.CertEnabled], auth2FASettings.options)) {
                        navigate(AUTH_TOTP_AND_CERT);
                    }
                    else if (isOptionsSettings([TwoFaOptions.TotpEnabled], auth2FASettings.options)) {
                        navigate(AUTH_TOTP);
                    }
                    else if (isOptionsSettings([TwoFaOptions.CertEnabled], auth2FASettings.options)) {
                        navigate(AUTH_CERT);
                    }
                }
                else {
                    errorAlert('Отсутствуют настройки авторизации');
                }
                if (auth2FASettings && auth2FASettings.certificates) {
                    dispatch(setUserCertificates(auth2FASettings.certificates.map(cert => {
                        if (IS_DEV) {
                            // eslint-disable-next-line no-console
                            console.log(`THUMBPRINT_USER: ${String(cert.thumbprint)}; VALID_TO: ${String(cert.validTo)}`);
                        }
                        return cert.thumbprint;
                    })));
                }
            }
            else {
                errorAlert('Сессионный ключ отсутствует');
            }
        })
            .catch((error) => {
            handleSetOpenPopup(true);
            handleSetErrorText(extractErrorMessage(error, DEFAULT_ERROR_TEXT));
            errorAlert(extractErrorMessage(error, DEFAULT_ERROR_TEXT));
        })
            .finally(() => {
            setLoading(false);
            reset();
        });
    }, [dispatch, errorAlert, handleSetErrorText, handleSetOpenPopup, navigate, reset, warningAlert]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.container }, loading ? (React.createElement(Preloader, null)) : (React.createElement("div", { className: styles.login },
            React.createElement("h1", { className: styles.title }, "\u0412\u043E\u0439\u0442\u0438"),
            React.createElement("form", { onSubmit: handleSubmit(onLoginSubmit) },
                React.createElement("div", { className: styles.label },
                    React.createElement(InputUI, { ...register('login', {
                            required: 'Введите логин',
                            disabled: loading,
                        }), title: '\u041B\u043E\u0433\u0438\u043D', errorText: errors.login?.message, hasError: !!errors?.login })),
                React.createElement("div", { className: styles.label },
                    React.createElement(InputUI, { isPasswordInput: true, ...register('password', {
                            required: 'Введите пароль',
                            disabled: loading,
                        }), title: '\u041F\u0430\u0440\u043E\u043B\u044C', errorText: errors.password?.message, hasError: !!errors?.password, type: 'password' })),
                React.createElement("div", { className: styles.submit },
                    React.createElement(RectButtonUI, { disabled: !isValid || loading, className: styles.enter, type: 'submit' }, "\u0412\u043E\u0439\u0442\u0438"))),
            React.createElement("div", { className: styles.restoreAccess },
                React.createElement(CustomLink, { to: FORGOT_PASSWORD, className: styles.restoreAccessText }, "\u0417\u0430\u0431\u044B\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?"))))),
        React.createElement(ConfirmErrorInfoPopup, { open: openPopup, onClose: onClosePopup, onSubmit: onConfirm, errorText: errorText })));
};
