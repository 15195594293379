import { useCookies } from 'react-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAlert, useCustomNavigate } from '../general-code-import/utils-import';
import { APP_LINK_COOKIE, goToAuth } from '../../../submodules/inkh-core-frontend/src/utils/auth';
import { getSessionKey } from '../../store/slice/loginSlice';
import { BASE_PATH } from '../../routes';
/** Достанет ссылку на приложение из cookie */
export const useAppLinkCookie = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const deleteAppNameCookie = useCallback(() => removeCookie(APP_LINK_COOKIE), [removeCookie]);
    const setAppNameCookie = useCallback((appName) => setCookie(APP_LINK_COOKIE, appName), [setCookie]);
    return [cookies[APP_LINK_COOKIE], setAppNameCookie, deleteAppNameCookie];
};
/**
 * Обработка наличия ссылки на переход в приложение после авторизации
 * */
export const useAppLinkWarning = (appLink) => {
    const { warningAlert } = useAlert();
    /** Успешный запуск функции */
    const [successfulRun, setSuccessfulRun] = useState(true);
    const executor = useCallback((cb) => {
        if (appLink) {
            cb(appLink);
        }
        else {
            warningAlert('Перейдите в приложение!', false);
            setSuccessfulRun(false);
        }
    }, [appLink, warningAlert]);
    return [successfulRun, executor];
};
/**
 * Возвращает сессионный ключ.
 * Если его нет, то редиректит на страницу логина.
 * */
export const useSessionKey = () => {
    const sessionKey = useSelector(getSessionKey);
    const navigate = useCustomNavigate();
    useEffect(() => {
        if (!sessionKey) {
            navigate(BASE_PATH);
        }
    }, [navigate, sessionKey]);
    return sessionKey;
};
/**
 * Возвращает состояние открытия попапа, текста ошибки и обработчики установки этих значений
 * */
export const useConfirmErrorInfoPopup = () => {
    /** Состояние открытия диалогового окна */
    const [openPopup, setOpenPopup] = useState(false);
    /** Текст ошибки */
    const [errorText, setErrorText] = useState('');
    /** Обработчик открытия диалогового окна */
    const handleSetOpenPopup = useCallback((isOpen) => {
        setOpenPopup(isOpen);
    }, []);
    /** Обработчик установки текста ошибки */
    const handleSetErrorText = useCallback((text) => {
        setErrorText(text);
    }, []);
    /** Обработчик закрытия попап */
    const onClosePopup = useCallback(() => {
        handleSetOpenPopup(false);
    }, [handleSetOpenPopup]);
    /** Подтверждение уведомления об ошибке */
    const onConfirm = useCallback(() => {
        goToAuth();
        onClosePopup();
    }, [onClosePopup]);
    return [openPopup, errorText, handleSetOpenPopup, handleSetErrorText, onClosePopup, onConfirm];
};
