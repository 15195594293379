import * as fns from 'date-fns';
import Cookie from 'js-cookie';
import { handleAPIError } from '../error-info';
import { createNullableStore } from '../smallFunctions';
/** Приложение авторизации */
const AUTH_URI = `${window.location.origin}/authRedirect`;
/** Смена пароля */
const CHANGE_PASSWORD_URI = `${window.location.origin}/passwordChangeRedirect`;
/** Имя cookie, в которых хранится ссылка на текущее приложение */
export const APP_LINK_COOKIE = 'appLink';
/** Имя cookie, в котором лежит время жизни токена */
const TOKEN_LIFE_TIME_COOKIE = 'tokenLifeTime';
/** Возвращает domain для проставления cookie */
const getCookieDomain = () => {
    /** Имя хоста */
    const { hostname } = window.location;
    /** Индекс первого разделения поддомена */
    const index = hostname.indexOf('.');
    return hostname.slice(index);
};
/** Установка ссылки на приложение */
const setAppLinkCookie = () => {
    Cookie.set(APP_LINK_COOKIE, window.location.href, { domain: getCookieDomain(), secure: true });
};
/** Переход к смене пароля */
export const goToChangePassword = () => {
    setAppLinkCookie();
    window.location.replace(CHANGE_PASSWORD_URI);
};
/** Функция перехода на страницу авторизации */
export const goToAuth = () => {
    setAppLinkCookie();
    window.location.replace(AUTH_URI);
};
/** Максимально допустимое время до окончания жизни токена */
const MAX_OFFSET = 5;
/** Возвращает время, сколько осталось жить токену */
export const getTimeCookie = () => {
    const dateString = Cookie.get(TOKEN_LIFE_TIME_COOKIE);
    if (dateString) {
        const expireTime = new Date(dateString).getTime();
        if (Number.isNaN(expireTime)) {
            return 0;
        }
        const currenTime = new Date().getTime();
        return (expireTime - currenTime) / 1000;
    }
    return undefined;
};
/** Устанавливает время жизни токена в куки */
export const setTimeCookie = (time) => {
    const expiresDate = fns.addSeconds(new Date(), time).toISOString();
    Cookie.set(TOKEN_LIFE_TIME_COOKIE, expiresDate, { domain: getCookieDomain(), secure: true });
};
/** Присваивание финкции по получению и созданию перехватываемого промиса к переменной */
const REFRESH_PROMISE_STORE = createNullableStore();
/** Обновит токен, если потребуется */
export const refreshIfNeeded = async (refresh, isNotGoToAuth) => {
    const tokenTimeCookie = getTimeCookie();
    if (tokenTimeCookie && tokenTimeCookie > MAX_OFFSET) {
        return;
    }
    try {
        const promise = refresh().then(setTimeCookie);
        REFRESH_PROMISE_STORE.set(promise);
        await promise;
        REFRESH_PROMISE_STORE.set(null);
    }
    catch {
        if (!isNotGoToAuth) {
            goToAuth();
        }
    }
};
/** Обработчик ошибок на запросы */
export const responseErrorInterceptor = async (error, refreshToken, retry, reportError, isNotGoToAuth) => {
    /** Обработка ошибки */
    handleAPIError(error, reportError);
    /** Оригинальный запрос, который упал с ошибкой */
    const originalRequest = error.config;
    const currentPromise = REFRESH_PROMISE_STORE.get();
    if (currentPromise) {
        return currentPromise
            .then(() => retry(originalRequest))
            .catch(() => {
            if (!isNotGoToAuth) {
                goToAuth();
            }
        });
    }
    /** Протухшая авторизация + первая попытка */
    if (error.response?.status === 401 && !originalRequest.isRetry) {
        originalRequest.isRetry = true;
        try {
            /** Дождаться обновления токена */
            const promise = refreshToken();
            REFRESH_PROMISE_STORE.set(promise.then(setTimeCookie));
            await promise;
            REFRESH_PROMISE_STORE.set(null);
        }
        catch (e) {
            if (!isNotGoToAuth) {
                /** Если токен обновить не получилось посылаем на страницу авторизации */
                goToAuth();
            }
        }
        /** Воспроизвести запрос еще раз после обновления токена */
        return retry(originalRequest);
    }
    /** Протухшая авторизация не первый раз для одного запроса */
    if (error.response?.status === 401) {
        if (!isNotGoToAuth) {
            goToAuth();
        }
    }
    /** Ошибка, не связанная с авторизацией */
    return Promise.reject(error);
};
/**
 * Добавляет проверку на ошибку 401 Unauthorized.
 * Автоматически рефрешит токены и повторяет исходный запрос.
 * Если оба токена протухли - отправляет на страницу авторизации.
 * @param axiosInstance - настроеннй экземпляр axios.
 * */
export const addAuthInterceptors = (axiosInstance, { refreshToken, reportError }, isNotGoToAuth) => {
    axiosInstance.interceptors.response.use(undefined, (error) => responseErrorInterceptor(error, refreshToken, axiosInstance, reportError, isNotGoToAuth));
    axiosInstance.interceptors.request.use(config => (REFRESH_PROMISE_STORE.get() ?? refreshIfNeeded(refreshToken, isNotGoToAuth)).then(() => config));
};
