import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './layout.module.scss';
import { BuildInfo, ErrorBoundary } from '../../utils/general-code-import/components-import';
import { Header } from '../header';
import { Navigation } from '../navigation';
export const Layout = () => (React.createElement("div", { className: styles.page },
    React.createElement(ErrorBoundary, null,
        React.createElement(Header, null),
        React.createElement(Navigation, null),
        React.createElement("div", { className: styles.loginGraphics }),
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.content },
                React.createElement(Outlet, null))),
        React.createElement(BuildInfo, null))));
