import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Certificates } from '../certificates';
import { RectButtonUI, Tabs } from '../../utils/general-code-import/components-import';
import { parametersTabs } from '../../utils/tabOptions';
import { getActiveTab, setActiveTab } from '../../store/slice/tabSlice';
import { useAppDispatch } from '../../store/store';
import { PinCodeInput } from '../pin-code-input';
import { initDigits } from '../../utils/variables';
import styles from './auth-layout.module.scss';
import { tokenClient } from '../../utils/api';
import { extractErrorMessage, useAlert } from '../../../submodules/inkh-core-frontend/src';
import { useAppLinkCookie, useAppLinkWarning, useSessionKey } from '../../utils/hooks';
import { setTimeCookie } from '../../../submodules/inkh-core-frontend/src/utils/auth';
/**
 * Макет для отображения авторизации по сертификату или пин коду
 * */
export const AuthLayout = ({ isTotp, isCert, destroyApp }) => {
    const dispatch = useAppDispatch();
    const activeTab = useSelector(getActiveTab);
    const { errorAlert } = useAlert();
    /** получение сессионного ключа */
    const sessionKey = useSessionKey();
    /** флаг отображения по табам */
    const [certificateTab, setCertificateTab] = useState(0);
    /** значения полей ввода пин кода */
    const [digits, setDigits] = useState(initDigits);
    /** Ссылка на приложение, на которое нужен редирект */
    const [appLink, , deleteAppLinkFromCookie] = useAppLinkCookie();
    /** Состояние загрузки */
    const [loading, setLoading] = useState(false);
    /** Обработка наличия appLink */
    const [render, runWithAppLink] = useAppLinkWarning(appLink);
    /** Описание табов */
    const tabs = useMemo(() => ({
        options: parametersTabs,
        activeTab,
        onChange: tab => {
            dispatch(setActiveTab(tab));
            setCertificateTab(tab.key);
        },
    }), [activeTab, dispatch]);
    /** Обработчик входа по 6ти значному коду */
    const onLoginBy2FACode = useCallback(() => {
        setLoading(true);
        const code = digits.join('');
        tokenClient
            .getTokenBy2FACode({ sessionKey, code })
            .then(tokenLifeTime => {
            setTimeCookie(tokenLifeTime);
            runWithAppLink(appLink => {
                /** Очистка приложеия */
                destroyApp();
                /** Удаление ссылки на приложение из cookie */
                deleteAppLinkFromCookie();
                /** Переход на само приложение */
                window.location.assign(appLink);
            });
        })
            .catch((error) => {
            errorAlert(extractErrorMessage(error));
        })
            .finally(() => {
            setDigits(initDigits);
            setLoading(false);
        });
    }, [deleteAppLinkFromCookie, destroyApp, digits, errorAlert, runWithAppLink, sessionKey]);
    /** флаг разблокировки кнопки Зайти */
    const nonEmptyStrings = digits.filter(str => str.trim().length > 0);
    return render ? (React.createElement(React.Fragment, null,
        !isTotp && !isCert && React.createElement(Tabs, { ...tabs }),
        React.createElement("div", { className: styles.container },
            React.createElement("h1", { className: styles.title }, (isTotp || certificateTab === 0) && !isCert
                ? 'Введите код двухфакторной авторизации'
                : 'Выберите сертификат'),
            (isTotp || certificateTab === 0) && !isCert && (React.createElement(PinCodeInput, { isNonEmptyStrings: nonEmptyStrings.length === 6, handleConfirm: onLoginBy2FACode, digits: digits, changeHandler: setDigits })),
            (isCert || certificateTab === 1) && React.createElement(Certificates, { destroyApp: destroyApp }),
            (isTotp || certificateTab === 0) && !isCert && (React.createElement("div", { className: styles.submit },
                React.createElement(RectButtonUI, { disabled: loading || nonEmptyStrings.length !== 6, onClick: onLoginBy2FACode, className: styles.enter }, "\u0417\u0430\u0439\u0442\u0438 \u043F\u043E \u043A\u043E\u0434\u0443")))))) : null;
};
