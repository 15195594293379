export const sectionsInfo = [
    {
        label: 'О КОМПАНИИ',
        nested: [
            {
                href: 'https://inkakhran.ru/about/today/',
                label: 'ИНКАХРАН',
            },
            {
                href: 'https://inkakhran.ru/about/news/',
                label: 'НОВОСТИ',
            },
            {
                href: 'https://inkakhran.ru/about/vacancy/',
                label: 'ВАКАНСИИ',
            },
            {
                href: 'https://inkakhran.ru/documents/statutory/',
                label: 'УСТАВНЫЕ ДОКУМЕНТЫ',
            },
        ],
    },
    {
        label: 'УСЛУГИ',
        href: 'https://inkakhran.ru/services/',
        nested: [
            {
                href: 'https://inkakhran.ru/services/retail/',
                label: 'РИТЕЙЛУ',
            },
            {
                href: 'https://inkakhran.ru/services/bank/',
                label: 'БАНКАМ',
            },
            {
                href: 'https://inkakhran.ru/services/public/',
                label: 'ГОССЕКТОРУ',
            },
            {
                href: 'https://inkakhran.ru/upload/docs/online-inkasacion.pdf',
                label: 'ОНЛАЙН ИНКАССАЦИЯ',
            },
        ],
    },
    {
        label: 'ДОКУМЕНТЫ',
        nested: [
            {
                href: 'https://inkakhran.ru/documents/terms/',
                label: 'ПРАВИЛА ОБСЛУЖИВАНИЯ',
            },
            {
                href: 'https://inkakhran.ru/documents/aml/',
                label: 'ПОД/ФТ',
            },
            {
                href: 'https://inkakhran.ru/documents/online/',
                label: 'ИНКАХРАН ОНЛАЙН',
            },
            {
                href: 'https://inkakhran.ru/documents/strategy/',
                label: 'СТРАТЕГИЯ',
            },
        ],
    },
    {
        label: 'РАСКРЫТИЕ',
        nested: [
            {
                href: 'https://inkakhran.ru/disclosure/annual/',
                label: 'ГОДОВАЯ ОТЧЕТНОСТЬ',
            },
            {
                href: 'https://inkakhran.ru/disclosure/financial/',
                label: 'ФИНАНСОВАЯ ОТЧЕТНОСТЬ',
            },
            {
                href: 'https://inkakhran.ru/disclosure/regulatory/',
                label: 'ИНФОРМАЦИЯ ДЛЯ РЕГУЛЯТИВНЫХ ЦЕЛЕЙ',
            },
            {
                href: 'https://inkakhran.ru/disclosure/risks/',
                label: 'УПРАВЛЕНИЕ РИСКАМИ',
            },
            {
                href: 'https://inkakhran.ru/disclosure/other/',
                label: 'ПРОЧЕЕ',
            },
        ],
    },
    {
        href: 'https://inkakhran.ru/contacts/',
        label: 'КОНТАКТЫ',
    },
];
