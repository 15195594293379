import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { appReducer } from './slice/appSlice';
import { loginReducer } from './slice/loginSlice';
import { tabReducer } from './slice/tabSlice';
export const store = configureStore({
    reducer: {
        app: appReducer,
        login: loginReducer,
        tabs: tabReducer,
    },
});
export const useAppDispatch = () => useDispatch();
