import axios from 'axios';
import { AuthClient } from '../../../submodules/inkh-keycloak/src/Inkh.Keycloak.Api.Client.TypeScript/Clients/AuthClient.Generated';
import { AccountManagementClient } from '../../../submodules/inkh-keycloak/src/Inkh.Keycloak.Api.Client.TypeScript/Clients/AccountManagementClient.Generated';
import { InformClient } from '../../../submodules/inkh-principal/src/Inkh.Principal.Api.Client.TypeScript/Clients/InformClient.Generated';
import { addAuthInterceptors } from '../../../submodules/inkh-core-frontend/src';
const axiosInstance = axios.create({
    withCredentials: true,
});
/** Клиент для отправки сообщений об ошибках */
const errorInformClient = new InformClient(IS_DEBUG ? 'https://inkh-lk-tst-web.mcb.ru:14011' : undefined, axios.create({
    withCredentials: true,
}));
/** Установка обработчика ошибок API */
addAuthInterceptors(axiosInstance, {
    reportError: info => errorInformClient.addUnknownErrorInfo(info),
    refreshToken: () => Promise.resolve(0),
}, true);
export const tokenClient = new AuthClient(IS_DEBUG ? 'https://inkh-lk-tst-web.mcb.ru:14005' : undefined, axiosInstance);
export const accountManagementClient = new AccountManagementClient(IS_DEBUG ? 'https://inkh-lk-tst-web.mcb.ru:14005' : undefined, axiosInstance);
